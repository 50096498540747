import CarouselElement from "./CarouselElement";
import data from "./carouseldata.json";
import getWindowDimensions from "../WindowDimensions";

function Carousel(props) {
  let elements;
  let buttons;
  let carouseldata = "";
  let dimensions= getWindowDimensions();
  let isSmall = dimensions.width <768;
  if (props.ident === "carouselShows" && isSmall) {
    const carouseldata = data.filter((data) => data.title === "mainSmall");
    const content = carouseldata[0].content;
    elements = (
      <div>
        {content.map((data) => {
          return (
            <CarouselElement
              link={data.link}
              image={data.image}
              altern={data.altern}
              active={data.active}
              key={data.altern}
            />
          );
        })}
      </div>
    );
  }else{
    const carouseldata = data.filter((data) => data.title === "main");
    const content = carouseldata[0].content;
    elements = (
      <div>
        {content.map((data) => {
          return (
            <CarouselElement
              link={data.link}
              image={data.image}
              altern={data.altern}
              active={data.active}
              key={data.altern}
            />
          );
        })}
      </div>
    );
  }
  if (props.ident === "packAhorro") {
    const carouseldata = data.filter((data) => data.title === "packAhorro");
    const content = carouseldata[0].content;
    elements = (
      <div>
        {content.map((data) => {
          return (
            <CarouselElement
              link={data.link}
              image={data.image}
              altern={data.altern}
              active={data.active}
            />
          );
        })}
      </div>
    );
  }
  if (props.type === "review") {
    if (props.ident === "carouselReviewDesktop") {
      carouseldata = data.filter((data) => data.title === "reviewDesktop");
    } else if (props.ident === "carouselReviewMobile") {
      carouseldata = data.filter((data) => data.title === "reviewMobile");
    }
    const content = carouseldata[0].content;
    elements = (
      <div>
        {content.map((data) => {
          return (
            <CarouselElement
              image={data.image}
              altern={data.altern}
              active={data.active}
              key={data.altern}
            />
          );
        })}
      </div>
    );
  }

  if (props.type === "show") {
    const carouseldata = data.filter((data) => data.title === props.ident);
    const content = carouseldata[0].content;
    elements = (
      <div>
        {content.map((data) => {
          return (
            <CarouselElement
              link={data.link}
              image={data.image}
              altern={data.altern}
              active={data.active}
              key={data.altern}
            />
          );
        })}
      </div>
    );
  }

  if (props.type === "activity") {
    const carouseldata = data.filter((data) => data.title === props.ident);
    const content = carouseldata[0].content;
    elements = (
      <div>
        {content.map((data) => {
          return (
            <CarouselElement
              link={data.link}
              image={data.image}
              altern={data.altern}
              active={data.active}
              key={data.altern}
            />
          );
        })}
      </div>
    );
  }

  if (props.buttons) {
    buttons = (
      <div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={"#" + props.ident}
          data-bs-slide="prev"
        >
          <i className="fas fa-arrow-left prev"></i>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={"#" + props.ident}
          data-bs-slide="next"
        >
          <i className="fas fa-arrow-right next"></i>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    );
  }
  return (
    <div
      id={props.ident}
      className="carousel slide"
      data-bs-ride="carousel"
      data-bs-interval={props.interval}
      data-aos={props.aos && "flip-up"}
      data-aos-duration={props.aos && "500"}
      data-aos-once={props.aos && "true"}
      pause="hover"
    >
      <div className="carousel-inner">{elements}</div>
      {buttons}
    </div>
  );
}

export default Carousel;
