import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./blogStyles.css";
import blogData from "./contenido.json";
import parse from "html-react-parser";

const Blog = ({ dirpath }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !blogData.hasOwnProperty(dirpath) ||
      blogData[dirpath].visible === "NO"
    ) {
      navigate("/error");
    }
  }, [dirpath, navigate]);

  if (!blogData.hasOwnProperty(dirpath) || blogData[dirpath].visible === "NO") {
    return null;
  }

  const stripHTML = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const { seo, sections } = blogData[dirpath];

  const renderArticles = () => (
    <div className="blog-index">
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords.join(", ")} />
        <meta name="robots" content={seo.robots.join(", ")} />
        <link rel="canonical" href={seo.siteUrl} />
      </Helmet>
      <h2 className="blog-index-title">-- Índice de Artículos --</h2>
      {blogData.blog.articles
        .filter((article) => article.visible !== "NO")
        .map((article) => (
          <div className="blog-index-item" key={article.id}>
            <Link to={article.path}>
              <h3>{parse(article.title)}</h3>
            </Link>
          </div>
        ))}
    </div>
  );

  const renderContent = () => {
    const {
      content: sectionContent,
      pdfDownload,
      videoDownload,
      publicationDate,
      title: sectionTitle
    } = sections[0];
    const articles = blogData.blog.articles.filter(
      (article) => article.visible !== "NO"
    );
    const currentArticleIndex = articles.findIndex(
      (article) => article.path === `/blog/${dirpath}`
    );
    const prevArticle = articles[currentArticleIndex - 1];
    const nextArticle = articles[currentArticleIndex + 1];

    return (
      <div className="container">
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords.join(", ")} />
          <meta name="robots" content={seo.robots.join(", ")} />
          <link rel="canonical" href={seo.siteUrl} />
        </Helmet>
        <header className="article-header">
          <h2 className="article-title">{parse(sectionTitle)}</h2>
        </header>
        <main>
          {sectionContent.map((section, index) => {
            const isLastSection = index === sectionContent.length - 1;
            const imagePosition =
              section.imgpos === "der" ? "derecha" : "izquierda";
            const {
              imageSrc,
              imageAlt,
              extraImageSrc,
              extraImageAlt,
              title,
              text,
            } = section;

            return (
              <section key={index} className={`bloque ${imagePosition}`}>
                {imageSrc !== "NO" && (
                  <a href={imageSrc} target="_blank" rel="noopener noreferrer">
                    <img src={imageSrc} alt={imageAlt} className="blog-image" title={imageAlt} />
                  </a>
                )}
                <div
                  className={`contenido ${
                    imageSrc === "NO" ? "full-width" : ""
                  }`}
                >
                  <h3 className="section-title">{parse(title)}</h3>
                  {parse(text)}
                </div>
                {extraImageSrc && (
                  <a
                    href={extraImageSrc}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={extraImageSrc}
                      alt={extraImageAlt}
                      className={`extra-blog-image`}
                      title={extraImageAlt}
                    />
                  </a>
                )}
                {isLastSection && (
                  <div className="publication-date">{publicationDate}</div>
                )}
              </section>
            );
          })}
        </main>
        {(pdfDownload !== "NO" || videoDownload !== "NO") && (
          <div className="footer_blog">
            <p className="footer_text">{sections[0].footerText}</p>
            <div className="buttons_container">
              {pdfDownload !== "NO" && (
                <a
                  href={pdfDownload.href}
                  className="boton-pdf"
                  title={pdfDownload.text}
                  target="_blank"
                  rel="noreferrer"
                >
                  {pdfDownload.buttonText}
                </a>
              )}
              {videoDownload !== "NO" && (
                <a
                  href={videoDownload.href}
                  className="boton-video"
                  title={videoDownload.text}
                  target="_blank"
                  rel="noreferrer"
                >
                  {videoDownload.buttonText}
                </a>
              )}
            </div>
          </div>
        )}
        <div className="navigation-buttons">
          <div className="navigation-placeholder">
            {prevArticle && (
              <Link to={prevArticle.path}>
                <button
                  className="navigationButtonsButton"
                  title={stripHTML(prevArticle.title)}
                >
                  <span className="arrow">←</span> {parse(prevArticle.title)}
                </button>
              </Link>
            )}
          </div>
          <div className="navigation-center">
            <Link to="/blog">
              <button className="navigationButtonsButton">Índice</button>
            </Link>
          </div>
          <div className="navigation-placeholder">
            {nextArticle && (
              <Link to={nextArticle.path}>
                <button
                  className="navigationButtonsButton"
                  title={stripHTML(nextArticle.title)}
                >
                  {parse(nextArticle.title)} <span className="arrow">→</span>
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="content">
      <div className="content__description">
              {dirpath === "blog" ? renderArticles() : renderContent()}
      </div>
    </main>
  );
};

export default Blog;
