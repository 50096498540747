import { useRef, useState, useEffect } from "react";
import Carousel from "./commons/Carousel/Carousel";
import { Link } from "react-router-dom";
import SEO from "@americanexpress/react-seo";
// import ReactGA from "react-ga";

function Home(props) {
  // ReactGA.pageview("/");
  const secondcardRef = useRef(); //Referencia de la segunda tarjeta
  const [heightCard, setHeightCard] = useState();

  useEffect(() => {
    if (secondcardRef.current) {
      function handleResize() {
        setHeightCard(secondcardRef.current.offsetHeight);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [heightCard]);

  return (
    // CONTENIDO PAGINA PRINCIPAL
    <div>
      <main className="content">
        <SEO
          title="Teatro Luminaria"
          description="Compañía de teatro infantil especializada en títeres y luz negra con obras de teatro en inglés y espectáculos en castellano. Teatro para colegios, salas de teatro, centros cívicos, etc."
          siteUrl="https://www.teatroluminaria.com"
          locale="es-ES"
          robots={['index', 'follow']}
          keywords={[
            "compañia",
            "teatro",
            "infantil",
            "teatro en inglés",
            "teatro en inglés para colegios",
            "teatro en castellano",
            "títeres",
            "luz negra",
            "luminaria",
            "educacion primaria",
            "educacion infantil",
            "teatro negro",
            "teatro para colegios",
            "centros cívicos",
            "obras de teatro en inglés",
            "Teatro para niños",
            "Teatro en inglés para escuelas",
            "Teatro escolar en España",
            "Teatro en Castellano",
            "Teatro Luminaria",
            "Títeres en teatro",
            "Innovación en teatro",
            "Teatro con tecnología",
            "Espectáculos familiares",
            "Teatro pedagógico",
            "Teatro artesanal",
            "Teatro con música original",
            "Actuaciones en vivo España",
            "Espectáculos educativos",
            "Material didáctico teatral",
            "Aprender inglés con teatro",
            "Luz negra en teatro",
            "Salas de teatro en España",
            "Teatro en colegios",
            "Experiencia teatral inmersiva",
            "Compañías de teatro en España",
            "Teatro para ciclo infantil",
            "Teatro para ciclo primaria",
            "Espectáculos de calidad",
            "Reseñas de Teatro Luminaria",
            "Actividades didácticas en teatro",
            "Proyecciones en teatro",
            "Robots en teatro",
            "Animátronicos en teatro",
            "Teatro de valores educativos",
            "Efectos de luz y sonido en teatro",
            "obras de teatro para educación infantil",
            "obras de teatro para educación primaria"
          ]}
        />
        {/* CARRUSEL PAGINA PRINCIPAL */}
        <Carousel
          ident="carouselShows"
          interval="5000"
          aos={true}
          buttons={false}
        />

        {/* TARJETAS */}
        <section className="content__information">
          {/* PRIMERA TARJETA */}
          <article
            id="firstcard"
            className="content__information__card"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="content__information__card__head">
              <Link to="/teatroingles">
                <div className="content__information__card__head__img--pos"></div>
              </Link>
              <h2 className="content__information__card__head__textImg">
                Descubre&nbsp;
                <br />
                nuestros espectáculos en inglés
              </h2>
            </div>
            <div
              className="content__information__card__frame"
              style={{ height: heightCard }}
            >
              <div className="content__information__card__frame__text">
                <p>
                  Espectáculos de teatro en inglés para colegios dirigidos para
                  los cursos de infantil y primaria, y adaptados por edades.
                </p>
                <p>Especializados en títeres y en luz negra.</p>
                <p>¡Una experiencia inolvidable!</p>
              </div>
            </div>
            <Link
              to="/teatroingles"
              className="content__information__card__button"
            >
              <button>Descúbrelo aquí</button>
            </Link>
          </article>

          {/* SEGUNDA TARJETA */}
          {/* TIENE MAS TEXTO */}
          <article
            id="secondcard"
            className="content__information__card"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="content__information__card__head">
              <Link to="/teatro">
                <div className="content__information__card__head__img--pos"></div>
              </Link>
              <h2 className="content__information__card__head__textImg">
                Descubre&nbsp;
                <br />
                nuestros espectáculos en castellano
              </h2>
            </div>
            <div
              className="content__information__card__frame"
              ref={secondcardRef}
            >
              <div className="content__information__card__frame__text">
                <p>
                  Espectáculos de teatro en castellano para salas de teatro,
                  festivales y campañas escolares dirigidos para público
                  infantil y familiar.
                </p>
                <p>Especializados en títeres y en luz negra.</p>
                <p>¡Disfruta del teatro con toda la familia!</p>
              </div>
            </div>
            <Link to="/teatro" className="content__information__card__button">
              <button>Descúbrelo aquí</button>
            </Link>
          </article>

          {/* TERCERA TARJETA */}
          <article
            id="thirdcard"
            className="content__information__card"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="content__information__card__head">
              <Link to="/cuentacuentos">
                <div className="content__information__card__head__img--pos"></div>
              </Link>
              <h2 className="content__information__card__head__textImg">
                Descubre&nbsp;
                <br />
                nuestros cuentacuentos
              </h2>
            </div>
            <div
              className="content__information__card__frame"
              style={{ height: heightCard }}
            >
              <div className="content__information__card__frame__text">
                <p>
                  Cuentacuentos infantiles para todo tipo de eventos con un
                  denominador común: el títere.
                </p>
                <p>
                  Celebra navidades, fiestas populares, etc. con entretenidas
                  historias.
                </p>
                <p>¿Quieres disfrutar y reír?</p>
              </div>
            </div>
            <Link
              to="/cuentacuentos"
              className="content__information__card__button"
            >
              <button>Descúbrelo aquí</button>
            </Link>
          </article>
        </section>
        <h3 style={{visibility:'hidden'}}>Teatro en inglés y Teatro Familiar</h3>
      </main>
    </div>
  );
}

export default Home;
