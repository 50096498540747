import { Link } from "react-router-dom";
import parse from "html-react-parser";
import data from "./cuentatalleresdata.json";
import Carousel from "../commons/Carousel/Carousel";
// import CreativeCommons from "./creativecommons";
import SEO from "@americanexpress/react-seo";
// import ReactGA from "react-ga";

function CuentaTalleres(props) {
  let newdata = "";
  let seoTitle = "";
  let seoDescription = "";
  let seoKeywords = [];
  let seoUrl = "";
  // let seoImg = "";
  // ReactGA.pageview("/" + props.dirpath);
  if (props.dirpath === "cuentacuentos") {
    newdata = data.filter((data) => data.type === "cuentacuentos");
    seoTitle = "Cuentacuentos";
    seoDescription =
      "Cuentacuentos infantiles y familiares de pequeño formato para colegios, centros cívicos, bibliotecas, fiestas o fechas señaladas.";
    seoKeywords = [
      "cuentacuentos",
      "infantil",
      "teatro",
      "cuento infantil",
      "cuento",
      "storytelling",
      "storytime",
      "biblioteca",
      "títeres",
      "navidad",
      "tronca de navidad",
      "diciembre",
      "fiestas del pilar",
      "aragon",
      "juegos",
      "libro cuentacuentos",
      "libro",
    ];
    seoUrl = "https://www.teatroluminaria.com/cuentacuentos";
    // seoImg =
    //   "https://www.teatroluminaria.com/img/activities/cuentacuentos/AJugar.png";
  } else if (props.dirpath === "talleres") {
    newdata = data.filter((data) => data.type === "talleres");
    seoTitle = "Talleres infantiles";
    seoDescription =
      "Talleres infantiles y familiares para colegios, centros cívicos, bibliotecas, fiestas o fechas señaladas. Muchas manualidades para hacer títeres de diferentes formas";
    seoKeywords = [
      "talleres infantiles",
      "taller",
      "infantil",
      "familiar",
      "bibliotecas",
      "centros cívicos",
      "manualidades",
      "colegios",
      "títeres",
      "luz negra",
      "calcetines",
      "títeres de hilo",
      "navidad",
      "tragachicos",
    ];
    seoUrl = "https://www.teatroluminaria.com/talleres";
    // seoImg =
    //   "https://www.teatroluminaria.com/img/activities/talleres/crazytines.png";
  }

  const activity = newdata[0].data;

  return (
    <main className="content">
      <div className="content__activities">
        {activity.map((data, index) => {
          return (
            <div
              className="content__activities__activity"
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-once="true"
              key={index}
            >
              <SEO
                title={seoTitle}
                description={seoDescription}
                keywords={seoKeywords}
                locale="es-ES"
                robots={["index", "follow"]}
                siteUrl={seoUrl}
              />
              {/*  Caratula  */}
              <div className="content__activities__activity__cover">
                <div className="content__activities__activity__cover__frame">
                  <picture>
                    <source
                      srcSet={data.imgcover + ".webp"}
                      type="image/webp"
                    ></source>
                    <img
                      className="content__activities__activity__cover__frame__img"
                      src={data.imgcover + ".png"}
                      alt={data.altcover}
                    />
                  </picture>
                </div>
              </div>
              {/*  Descripción  */}
              <div className="content__activities__activity__description">
                <div className="content__activities__activity__description__frame">
                  <div className="content__activities__activity__description__frame__inner">
                    <div className="content__activities__activity__description__frame__inner__text">
                      <h3>{data.title}</h3>
                      {parse(data.description)}
                    </div>
                    {/*  Informacion y precios  */}
                    <Link
                      to="/contacto"
                      className="content__activities__activity__description__frame__inner__button"
                    >
                      <button>Información y precios</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* Galería */}
        <div
          className="content__activities__gallery"
          data-aos="slide-up"
          data-aos-duration="500"
        >
          <h2 className="content__activities__gallery__title">Galería:</h2>
          <div className="content__activities__gallery__frame">
            <div className="content__activities__gallery__frame__inner">
              <Carousel
                id="carouselactivity"
                ident={props.dirpath}
                type="activity"
                interval="5000"
                aos={true}
                buttons={true}
              />
            </div>
          </div>
        </div>
        {/* Creative Commons */}
        <div className="content__activities__license">
          {/* <a
            rel="license noreferrer"
            href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.es_ES"
            target="_blank"
          >
            <img
              alt="Licencia de Creative Commons"
              style={{ borderWidth: 0 }}
              src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
            />
          </a>
          <p>
            <CreativeCommons dirpath={props.dirpath} /> pertenece a &nbsp;
            <a
              rel="noreferrer"
              href="https://www.teatroluminaria.com"
              target="_blank"
            >
              Teatro Luminaria
            </a>
            , registrado con una licencia:
            <br />
            <a
              rel="license noreferrer"
              href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.es_ES"
              target="_blank"
            >
              Creative Commons Attribution-NonCommercial-NoDerivatives 4.0
              Internacional License
            </a>
            .
          </p> */}
        </div>
      </div>
    </main>
  );
}

export default CuentaTalleres;
