import Carousel from "../commons/Carousel/Carousel";
import SEO from "@americanexpress/react-seo";
// import ReactGA from "react-ga";

function Company() {
  // ReactGA.pageview("/compania");
  return (
    <main className="content">
      <SEO
        title="Compañía Teatro Luminaria"
        description="Compañía de teatro infantil especializada en títeres y luz negra con obras de teatro en inglés y espectáculos en castellano. Teatro para colegios, salas de teatro, centros cívicos, etc."
        locale="es-ES"
        robots={["index", "follow"]}
        siteUrl="https://www.teatroluminaria.com/compania"
        keywords={[
          "compañia",
          "teatro",
          "infantil",
          "teatro en inglés",
          "teatro en castellano",
          "títeres",
          "luz negra",
          "luminaria",
          "teatro para colegios",
          "centros cívicos",
          "obras de teatro en inglés",
        ]}
      />
      {/* Galería */}
      <div
        className="content__gallery"
        data-aos="slide-up"
        data-aos-duration="500"
      >
        <h3 style={{visibility:'hidden'}}>Teatro en inglés y Teatro Familiar</h3>

        <div className="content__gallery__frame__inner">
          <Carousel
            id="carouselcompany"
            ident="company"
            type="activity"
            interval="5000"
            aos={true}
            buttons={true}
          />
        </div>
      </div>
      {/*  Descripción  */}
      <div className="content__description">
        <div className="content__description__frame">
          <div className="content__description__frame__inner">
            <div className="content__description__frame__inner__text">
              <p>
                🎭 <b>¡Bienvenidos a Teatro Luminaria! 🎭</b>
                <br />
                <i>Donde la magia del escenario cobra vida en cada detalle</i>
              </p>

              <p>
                ¿Buscas una experiencia teatral que se sienta como un soplo de
                aire fresco pero que destile pura artesanía? ¡Estás en el lugar
                correcto! Somos dos apasionados del teatro que combinamos{" "}
                <b>20 años de experiencia acumulada</b> con casi{" "}
                <b>10 años de existencia de la compañía</b>, por lo que, a día
                de hoy, tenemos un extenso repertorio de fantásticas críticas y
                clientes más que satisfechos que respaldan nuestra pasión.
              </p>
              <p>
                🎬 <b>Una Empresa, Dos Artesanos</b> 🎬
                <br />
                Hacemos todo en nuestro taller y sí, somos solo nosotros dos.
                Desde la invención de guiones hasta la creación de vestuario,
                títeres, animatrónicos, música y canciones originales. Ah, y no
                olvidemos el marketing, la web, las actividades didácticas, los
                vídeos, y todo lo demás. ¡Nos encargamos de actuar e incluso de
                montar y desmontar nuestro propio escenario en teatros, colegios
                y salas por todo el país y más allá!{" "}
                <b>
                  Nuestro pequeño tamaño no significa menos calidad, sino más
                  cuidado y atención en cada aspecto de lo que hacemos.
                </b>
              </p>
              <p>
                🤖 <b>Innovación en Cada Acto</b> 🤖
                <br />
                Nuestros espectáculos son un despliegue de innovación, desde
                títeres animatrónicos y robots hasta proyecciones, música
                original, y efectos de luces y sonido diseñados meticulosamente.
                La innovación no es solo una palabra de moda para nosotros; es
                nuestra naturaleza.
              </p>
              <p>
                🎼 <b>Más que Teatro: Una Experiencia</b> 🎼
                <br />
                Fusionamos luz negra, títeres, video, personajes inolvidables,
                música, luces y una narrativa cautivadora para crear ambientes
                inmersivos. Nuestro objetivo no es solo entretener, sino
                emocionar, conectar y, sobre todo, hacer que tanto niños como
                adultos se sientan parte de la magia.
              </p>
              <p>
                📚 <b>Expertos en Educación</b> 📚
                <br />
                Gracias a nuestra experiencia y formación en educación y
                pedagogía, no solo aportamos valor a cada producción teatral,
                sino también a la experiencia educativa completa. Incorporamos
                material didáctico relevante en cada espectáculo, alineado con
                las más actuales "situaciones de aprendizaje" o "unidades
                didácticas", para garantizar un enriquecimiento integral y
                actualizado.
              </p>
              <p>
                💌 <b>Conéctate con Nosotros</b> 💌
                <br />
                Te invitamos a que descubras más sobre nuestra visión única del
                teatro navegando por nuestra web y siguiéndonos en redes
                sociales. ¿Tienes una pregunta o simplemente quieres decir
                'hola'? Estamos a solo un clic o una llamada de distancia.
              </p>
              <p>
                <b>¡Nos vemos en la magia del teatro! 🎭✨</b>
              </p>
            </div>
            {/*  Informacion y precios  */}
            <a
              href="img/company/dossier/DossierTeatroLuminaria.pdf#view=FitV"
              target="_blank"
              rel="noopener noreferrer"
              className="content__description__frame__inner__button"
            >
              <button>Descarga nuestro dossier informativo</button>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Company;
