// import ReactGA from "react-ga";
import Carousel from "../commons/Carousel/Carousel";
import { Link } from "react-router-dom";
import SEO from "@americanexpress/react-seo";

function Pack(props) {
  // ReactGA.pageview("/" + props.dirpath);
  var { actualYear, nextYear } = getYear();
  return (
    <main class="content">
    <SEO
        title="Packs de Ahorro"
        locale="es-ES"
      />
      {/* CARRUSEL */}
      <div class="content__pack" data-aos="slide-left" data-aos-duration="500">
        <Carousel ident="packAhorro" interval="5000" buttons={false} />
      </div>
      {/*  DESCRIPCIÓN  */}
      <div class="content__description">
        <div class="content__description__frame">
          <div
            class="content__description__frame__inner"
            style={{ backgroundColor: "#f5f8fa" }}
          >
            <div class="content__description__frame__inner__text">
              <span style={{ fontSize: "18px" }}>
                Descubre la forma más económica de organizar teatro en inglés en
                tu colegio con nuestros '
                <span style={{ color: "#54b9e8", fontWeight: "bold" }}>
                  Packs de Ahorro
                </span>
                ' para este curso {actualYear}/{nextYear}.<br />
                <br />
                Contrata dos espectáculos diferentes que cubran todo infantil y
                primaria y consigue un precio{" "}
                <span style={{ color: "#54b9e8", fontWeight: "bold" }}>
                  desde 2€ por alumno participante
                </span>
                . (
                <a
                  href="https://www.teatroluminaria.com/contacto"
                  style={{ textDecoration: "underline" }}
                >
                  Consultar condiciones
                </a>
                )<br />
                <br />
                <em>
                  <strong>Ejemplo:</strong> <br />
                  Si contratas "
                  <a
                    href="https://www.teatroluminaria.com/emotion"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    e-Motion
                  </a>
                  " para toda la etapada de infantil, 1º y 2º de Primaria y,
                  también "
                  <a
                    href="https://www.teatroluminaria.com/trapped"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    #Trapped!
                  </a>
                  " para la etapa de 3º a 6º de Primaria podrás aprovecharte de
                  los precios de los 'Packs de ahorro'.
                </em>
                <br />
                <br />
                <br />
              </span>

              {/* DOSSIER (HE COGIDO EL CSS DE LOS ENLACES DEL FOOTER) */}
              <div className="footer__contact">
                <p className="footer__contact__info__email">
                  <a href="https://teatroluminaria.com/img/company/dossier/DossierTeatroLuminaria.zip">
                    Descarga el dossier de nuestros espectáculos
                  </a>
                </p>
              </div>

              {/* BOTON */}
              <Link
                to="/contacto"
                className="content__show__synopsis__frame__inner__button"
              >
                <button>CONTRATA AHORA</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Pack;

function getYear() {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var actualYear = year;
  if (month < 8) {
    actualYear = year - 1;
  }
  var nextYear = actualYear + 1;
  return { actualYear, nextYear };
}
