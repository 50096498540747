import img404 from "../../assets/img/404.png";
import img404Webp from "../../assets/img/404.webp";

function page404() {
  return (
    <div className="content404">
      <div className="content404__items">
        <div className="content404__items__img">
          <picture>
            <source srcSet={img404Webp} type="image/webp"></source>
            <img src={img404} alt="404" />
          </picture>
        </div>
        <div className="content404__items__text">
          <h1>ERROR 404</h1>
          <h2>¡Ups! No existe la página</h2>
          <p>
            ¡La hemos liado! Al parecer, esta página no existe.
            <br />
            Selecciona un elemento del menú y vuelve a intentarlo.
          </p>
        </div>
      </div>
    </div>
  );
}

export default page404;
