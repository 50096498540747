import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [detection, setDetection] = useState(false);
  return (
    <UserContext.Provider value={{ detection, setDetection }}>
      {children}
    </UserContext.Provider>
  );
};
