import Cards from "./Cards";
// import ReactGA from "react-ga";

function Shows(props) {
  // if (props.dirpath === "castellano") {
  //   ReactGA.pageview("/teatro");
  // } else if (props.dirpath === "ingles") {
  //   ReactGA.pageview("/teatroingles");
  // }

  return (
    <main className="content">
      <div className="content__shows">
        <Cards dirpath={props.dirpath} />
      </div>
    </main>
  );
}

export default Shows;
