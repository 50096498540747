import HTMLFlipBook from "react-pageflip";
import DossierPage from "./DossierPage";

function Dossier(props) {
  return (
    <HTMLFlipBook
      width={300}
      height={300}
      size={"stretch"}
      maxWidth={600}
      showCover={true}
    >
      <DossierPage images={props.images} />
    </HTMLFlipBook>
  );
}

export default Dossier;
