// import ReactGA from "react-ga";

function Regalo(props) {
  // ReactGA.pageview("/" + props.dirpath);
  let enlace1, enlace2, nombre1, nombre2 = "";
  if (props.dirpath==='regalo_sinfonias'){
    enlace1="regalos/Ahora tengo una sombrilla.mp3";
    nombre1="AHORA TENGO UNA SOMBRILLA";
    enlace2="regalos/Es el Final.mp3";
    nombre2="ES EL FINAL";
  };
  if (props.dirpath==='regalo_sinfonias2'){
    enlace1="regalos/Tarta Espacial.mp3";
    nombre1="TARTA ESPACIAL";
    enlace2="regalos/La Canción de Billy.mp3";
    nombre2="LA CANCIÓN DE BILLY";
  };
  return (
    <main class="content">
      {/*  DESCRIPCIÓN  */}
      <div class="content__description">
        <div class="content__description__frame">
          <div
            class="content__description__frame__inner"
            style={{ backgroundColor: "#f5f8fa" }}
          >
            <div class="content__description__frame__inner__text">
              <img
                className="tituloregalo"
                src="/img/regalo/caja.png"
                align="center"
                alt="titulo_regalo"
              />
              <span style={{ fontSize: '22px' }}>➡️ <a href={enlace1}>{nombre1}</a> ⬅️</span><br/><br/>
              <span style={{ fontSize: '22px' }}>➡️ <a href={enlace2}>{nombre2}</a> ⬅️</span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Regalo;
