import Logo from "../Logo";
import ReviewButtonWebp from "../../../assets/img/buttons/review.webp";
import FaceDeskWebp from "../../../assets/img/rss/SigFace.webp";
import InstaDeskWebp from "../../../assets/img/rss/SigInsta.webp";
import TwitDeskWebp from "../../../assets/img/rss/SigTwit.webp";
import YoutubeDeskWebp from "../../../assets/img/rss/youtube.webp";
import ReviewButton from "../../../assets/img/buttons/review.png";
import FaceDesk from "../../../assets/img/rss/SigFace.png";
import InstaDesk from "../../../assets/img/rss/SigInsta.png";
import TwitDesk from "../../../assets/img/rss/SigTwit.png";
import YoutubeDesk from "../../../assets/img/rss/youtube.png";
import Carousel from "../Carousel/Carousel";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <LazyLoadComponent>
      <footer className="footer">
        <div className="footer__line"></div>
        <div className="footer__contact">
          <Logo
            css="footer__contact__logo"
            dataaos="zoom-in"
            dataaosduration="1000"
          />
          <div className="footer__contact__info">
            <p
              className="footer__contact__info__title"
              data-aos="slide-down"
              data-aos-duration="500"
              data-aos-once="true"
            >
              Teatro Luminaria
            </p>
            <p
              className="footer__contact__info__email"
              data-aos="slide-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <a href="mailto:contacto@teatroluminaria.com">
                contacto@teatroluminaria.com
              </a>
            </p>
            <p
              className="footer__contact__info__phone"
              data-aos="slide-left"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <a href="tel:+34657535101"> 657 535 101</a>
            </p>
            <p
              className="footer__contact__info__whatsapp"
              data-aos="fade"
              data-aos-duration="3500"
              data-aos-once="true"
            >
              <a
                href="https://wa.me/34657535101"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-whatsapp"></i>Contacta por Whatsapp
              </a>
            </p>
          </div>
        </div>
        {/* REDES MOVIL */}
        <div className="footer__banners--mobile" data-aos="fade-up">
          <a href="https://www.facebook.com/teatroluminaria" target="blank">
            <i className="fa fa-facebook-square"></i>
          </a>
          <a href="https://instagram.com/teatro_luminaria/" target="blank">
            <i className="fab fa-instagram-square"></i>
          </a>
          <a href="https://twitter.com/teatroluminaria" target="blank">
            <i className="fab fa-twitter-square"></i>
          </a>
          <a
            href="https://www.youtube.com/c/Teatroluminariaydanza/videos"
            target="blank"
          >
            <i className="fab fa-youtube-square"></i>
          </a>
        </div>
        {/* REDES DESKTOP */}
        <div className="footer__banners--desktop" data-aos="fade-up">
          <a href="https://www.facebook.com/teatroluminaria" target="blank">
            <picture>
              <source srcSet={FaceDeskWebp} type="image/webp"></source>
              <img src={FaceDesk} alt="sigFace" />
            </picture>
          </a>
          <a href="https://instagram.com/teatro_luminaria/" target="blank">
            <picture>
              <source srcSet={InstaDeskWebp} type="image/webp"></source>
              <img src={InstaDesk} alt="sigInsta" />
            </picture>
          </a>
          <a href="https://twitter.com/teatroluminaria" target="blank">
            <picture>
              <source srcSet={TwitDeskWebp} type="image/webp"></source>
              <img src={TwitDesk} alt="SigTwit" />
            </picture>
          </a>
          <a
            href="https://www.youtube.com/c/Teatroluminariaydanza/videos"
            target="blank"
          >
            <picture>
              <source srcSet={YoutubeDeskWebp} type="image/webp"></source>
              <img src={YoutubeDesk} alt="SigYou" />
            </picture>
          </a>
        </div>
        {/* CARRUSEL OPINIONES */}
        <div
          className="footer__reviews"
          data-aos="zoom-in-up"
          data-aos-once="true"
        >
          <h2 className="footer__reviews__title">Opiniones</h2>

          {/* CARRUSEL MOVIL */}
          <Carousel
            type="review"
            ident="carouselReviewMobile"
            interval="10000"
            aos={false}
            buttons={true}
          />

          {/* CARRUSEL DESKTOP */}
          <Carousel
            type="review"
            ident="carouselReviewDesktop"
            interval="30000"
            aos={false}
            buttons={true}
          />
          <a
            className="footer__reviews__button"
            href="https://g.page/r/CRgGVtws0n6tEAg/review"
            target="blank"
          >
            <picture>
              <source srcSet={ReviewButtonWebp} type="image/webp"></source>
              <img
                className="footer__reviews__button__img"
                src={ReviewButton}
                alt="buttonReview"
                data-aos="flip-up"
              />
            </picture>
          </a>
        </div>
        <div className="footer__widgets">
          {/* Snapwidget Facebook */}

          <div
            className="footer__widgets__face"
            data-aos="slide-right"
            data-aos-once="true"
          >
            <h2 className="footer__widgets__face__title">Nuestro Facebook</h2>
            <div className="footer__widgets__face__frame">
              <iframe
                src="https://snapwidget.com/embed/915224"
                className="snapwidget-widget"
                allowtransparency="true"
                frameBorder="0"
                scrolling="no"
                title="facebook"
                style={{ height: "100%" }}
              ></iframe>
            </div>
          </div>
          {/* Snapwidget Instagram */}
          <div
            className="footer__widgets__insta"
            data-aos="slide-left"
            data-aos-once="true"
          >
            <h2 className="footer__widgets__insta__title">Nuestro Instagram</h2>
            <div className="footer__widgets__insta__frame">
              <iframe
                src="https://snapwidget.com/embed/915223"
                className="snapwidget-widget"
                allowtransparency="true"
                frameBorder="0"
                scrolling="no"
                title="instagram"
                style={{ height: "100%" }}
              ></iframe>
            </div>
          </div>
        </div>
        {/* Enlaces Finales */}
        <div className="footer__links">
          <Link to="/avisolegal">Aviso legal</Link>
          <a href="http://www.teatroeninglés.es">www.teatroeninglés.es</a>
        </div>
      </footer>
      <script src="https://snapwidget.com/js/snapwidget.js"></script>
    </LazyLoadComponent>
  );
}

export default Footer;
