import logo from "../../assets/img/luminariaLogo.webp";
import logoSmall from "../../assets/img/luminariaLogoSmall.webp";
import getWindowDimensions from "./WindowDimensions";

function Logo(props) {
  let dimensions = getWindowDimensions();
  let isSmall = dimensions.width < 768;
  if (isSmall) {
    return (
      <img
        className={props.css}
        src={logoSmall}
        alt="logotipo"
        data-aos={props.dataaos}
        data-aos-duration={props.dataaosduration}
        width="100%"
        height="100%"
      />
    );
  } else {
    return (
      <img
        className={props.css}
        src={logo}
        alt="logotipo"
        data-aos={props.dataaos}
        data-aos-duration={props.dataaosduration}
        width="100%"
        height="100%"
      />
    );
  }
}

export default Logo;
