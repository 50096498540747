import { useState, useEffect } from "react";
import datashows from "../Shows/showdata.json";
import dataactiv from "../Activities/cuentatalleresdata.json";
import parse from "html-react-parser";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReCaptcha } from "react-use-recaptcha-v3";
// import env from "react-dotenv";
import SEO from "@americanexpress/react-seo";
import phpMail from "./email.php";
// import ReactGA from "react-ga";

function getActivities() {
  //Recojo todas las actividades de Luminaria para incluirlos en el select desplegable
  const showsdata = datashows.filter(
    (data) => data.data[0].language === "english"
  );
  let shows = showsdata.map((data) => data.data[0].titleshow);
  shows = ["------Espectáculos en inglés------"].concat(shows);
  const especdata = datashows.filter(
    (data) => data.data[0].language === "spanish"
  );
  let espec = especdata.map((data) => data.data[0].titleshow);
  espec = ["------Espectáculos en castellano------"].concat(espec);
  const datacuenta = dataactiv.filter((data) => data.type === "cuentacuentos");
  let cuentos = datacuenta[0].data.map((data) => data.title);
  cuentos = ["------Cuentacuentos------"].concat(cuentos);
  const datataller = dataactiv.filter((data) => data.type === "talleres");
  let talleres = datataller[0].data.map((data) => data.title);
  talleres = ["------Talleres------"].concat(talleres);
  const otros = ["------Otros------", "Pack Ahorro", "Otra consulta"];
  const actividades = shows.concat(
    espec.concat(cuentos.concat(talleres.concat(otros)))
  );
  return actividades;
}

function Contact() {
  // ReactGA.pageview("/contacto");
  useEffect(() => {
    const msg01 = () => (
      <div className="toast-message">
        <center>
          Rellene el formulario o haga click para enviarnos un whatsapp:
          <br />
          <h4>
            <a
              href="https://wa.me/34657535101"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                textShadow: "2px 2px 2px #b3eaff",
              }}
            >
              <i
                className="fab fa-whatsapp"
                style={{ marginRight: "5px" }}
                aria-hidden="true"
                id="whatsapp"
              ></i>
              657535101
            </a>
          </h4>
        </center>
      </div>
    );
    const msg02 = () => (
      <div className="toast-message">
        <center>
          O también puede llamarnos por teléfono:
          <br />
          <h4>
            <a
              href="tel:+34657535101"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                textShadow: "2px 2px 2px #b3eaff",
              }}
            >
              <i
                className="fas fa-phone-alt"
                style={{ marginRight: "5px" }}
                aria-hidden="true"
                id="phone"
              ></i>
              657535101
            </a>
          </h4>
        </center>
      </div>
    );
    const msg03 = () => (
      <div className="toast-message">
        <center>
          O puede enviarnos un correo electrónico:
          <br />
          <h6>
            <a
              href="mailto:contacto@teatroluminaria.com"
              style={{
                textDecoration: "underline",
                textShadow: "1px 1px 2px #b3eaff",
              }}
            >
              <i
                className="fas fa-envelope"
                style={{ marginRight: "5px" }}
                aria-hidden="true"
                id="mail"
              ></i>
              contacto@teatroluminaria.com
            </a>
          </h6>
        </center>
      </div>
    );
    toast.success(msg01, {
      position: "top-right",
      delay: 3000,
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      icon: false,
      toastId: "whatsapp",
    });
    toast.warning(msg02, {
      position: "top-right",
      delay: 6000,
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      icon: false,
      toastId: "telefono",
    });
    toast.info(msg03, {
      position: "top-right",
      delay: 9000,
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      icon: false,
      toastId: "email",
    });
  }, []);
  //Establezco todas las constantes y useStates necesarios
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const actionName = "submit";
  const { execute } = useReCaptcha(siteKey, actionName);

  const actividades = getActivities();
  const [centro, setCentro] = useState(null);
  const [conditions, setConditions] = useState(false);
  const [isSubmitted, setisSubmitted] = useState(false);
  const [form, setForm] = useState({
    Nombre: "",
    NombreCentro: "",
    Localidad: "",
    Provincia: "",
    eMail: "",
    Telefono: "",
    Horario: "",
    NumeroAlumnos: "",
    Actividad: "",
    Consulta: "",
    Recaptcha: "",
    RecapSecret: "",
  });
  const [phone, setPhone] = useState(false);

  //Mensaje de envio correcto
  const msgSuccess = ({ closeToast }) => (
    <div>
      <center>
        👍 Mensaje enviado correctamente 👍
        <br />
        Recibirá un correo electrónico confirmando la recepción de su mensaje.
        <br />
        Si no lo recibe, revise la <b>carpeta SPAM</b> o contacte por teléfono
        con nosotros.
      </center>
    </div>
  );

  //Mensaje de envio fallido
  const msgFail = ({ closeToast }) => (
    <div>
      <center>
        😢 Ha ocurrido un error 😢
        <br /> Vuelva a intentarlo en unos minutos o escriba un correo a:
        <br />
        contacto@teatroluminaria.com
      </center>
    </div>
  );

  //Añado elementos al objeto que contiene los datos del formulario
  const addElement = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //Establezco si ha aceptado las condiciones
  function handleClickConditions() {
    setConditions(!conditions);
  }

  // Duplico el addElement solo para el Telefono porque necesito incluirlo en el objeto y poner el setPhone en true
  const handlePhone = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setPhone(true);
  };

  //Establezco si es un colegio o un particular y cambio el formulario
  function handleSelect(selection) {
    setCentro(selection);
  }

  // Solicito el token y el secret del reCAPTCHA y lo añado al objeto del formulario
  const submitForm = async () => {
    try {
      const token = await execute();
      form.Recaptcha = token;
      form.RecapSecret = process.env.REACT_APP_RECAPTCHA_SECRETKEY;

      // Ejecuto axios para mandar el post después de obtener el token
      const response = await axios({
        method: "POST",
        url: phpMail,
        data: JSON.stringify(form),
        headers: { "Content-Type": "application/json" },
      });

      // Según la respuesta, mostraré diferentes alertas
      setisSubmitted(false); // Desbloqueo el formulario
      if (response.data.status === "success") {
        toast.success(msgSuccess, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        resetForm(); // Elimino los datos del formulario
      } else if (response.data.status === "fail") {
        toast.error(msgFail, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.data.status === "wrongrecaptcha") {
        toast.error(
          "Error de verificación de Google. Vuelva a pulsar <u>'Enviar Consulta'</u>",
          {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error) {
      console.error(
        "Error al obtener el token de reCAPTCHA o al enviar el formulario:",
        error
      );
    }
  };

  // Envío los datos del formulario al PHP al hacer clic en el botón de enviar
  function handleSubmit(e) {
    e.preventDefault(); // Previene las acciones del submit por defecto
    setisSubmitted(true); // Bloqueo el formulario para que el usuario vea el cambio
    submitForm();
  }

  //Reseteo el formulario
  function resetForm() {
    setForm({
      Nombre: "",
      NombreCentro: "",
      Localidad: "",
      Provincia: "",
      eMail: "",
      Telefono: "",
      Horario: "",
      NumeroAlumnos: "",
      Actividad: "",
      Consulta: "",
    });
    setPhone(false);
    setCentro(null);
    setConditions(false);
  }

  return (
    <main className="content">
      {/*  Descripción  */}
      <SEO
        title="Contacto Teatro Luminaria"
        description="Contacto para compañía de teatro infantil especializada en títeres y luz negra con obras de teatro en inglés y espectáculos en castellano. Teatro para colegios, salas de teatro, centros cívicos, etc."
        locale="es-ES"
        robots={["index", "follow"]}
        siteUrl="https://www.teatroluminaria.com/contacto"
        keywords={[
          "compañia",
          "teatro",
          "infantil",
          "teatro en inglés",
          "teatro en castellano",
          "títeres",
          "luz negra",
          "luminaria",
          "teatro para colegios",
          "centros cívicos",
          "obras de teatro en inglés",
        ]}
      />
      <div className="content__contact">
        {/* Generador de alertas */}
        <ToastContainer />
        <div className="content__contact__frame">
          <div className="content__contact__frame__inner">
            <h3 className="content__contact__frame__inner__title">
              Solicitud de Información
            </h3>
            <form
              id="contact-form"
              onSubmit={handleSubmit.bind(this)}
              method="POST"
            >
              <p className="content__contact__frame__inner__subtitle">
                *<span className="required">Campos obligatorios</span>
              </p>
              <div className="form-group mb-2 centro">
                <label className="centrolabel">
                  ¿Pertenece usted a un centro educativo?
                </label>
                <br />

                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="Pertenece"
                    name="centrooption"
                    required
                    onClick={() => handleSelect(true)}
                    checked={
                      centro !== null
                        ? centro
                          ? true
                          : false
                        : centro === null && false
                    }
                    defaultValue={null}
                  />
                  <label
                    htmlFor="Pertenece"
                    className="form-label col-form-label-sm"
                  >
                    Si
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="NoPertenece"
                    name="centrooption"
                    required
                    onClick={() => handleSelect(false)}
                    checked={
                      centro !== null
                        ? centro
                          ? false
                          : true
                        : centro === null && false
                    }
                    defaultValue={null}
                  />
                  <label
                    htmlFor="NoPertenece"
                    className="form-label col-form-label-sm"
                  >
                    No
                  </label>
                </div>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  required
                  name="Nombre"
                  id="Nombre"
                  placeholder="Nombre y Apellidos"
                  onChange={addElement}
                  value={form.Nombre}
                  disabled={isSubmitted ? true : false}
                />
                <label
                  htmlFor="Nombre"
                  className="form-label col-form-label-sm"
                >
                  Nombre y apellidos: <span className="required">*</span>
                </label>
              </div>
              <div
                className="form-floating mb-3"
                style={centro ? { display: "block" } : { display: "none" }}
              >
                <input
                  type="text"
                  className="form-control"
                  required={centro ? true : false}
                  name="NombreCentro"
                  id="NombreCentro"
                  placeholder="Centro Educativo"
                  onChange={addElement}
                  value={form.NombreCentro}
                  disabled={isSubmitted ? true : false}
                />
                <label
                  htmlFor="NombreCentro"
                  className="form-label col-form-label-sm"
                >
                  Nombre centro educativo: <span className="required">*</span>
                </label>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="Localidad"
                      id="Localidad"
                      placeholder="Localidad"
                      onChange={addElement}
                      value={form.Localidad}
                      disabled={isSubmitted ? true : false}
                    />
                    <label
                      htmlFor="Localidad"
                      className="form-label col-form-label-sm"
                    >
                      Localidad:
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      required
                      name="Provincia"
                      id="Provincia"
                      placeholder="Provincia"
                      onChange={addElement}
                      value={form.Provincia}
                      disabled={isSubmitted ? true : false}
                    />
                    <label
                      htmlFor="Provincia"
                      className="form-label col-form-label-sm"
                    >
                      Provincia: <span className="required">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      required
                      name="eMail"
                      id="eMail"
                      placeholder="e-Mail"
                      onChange={addElement}
                      value={form.eMail}
                      disabled={isSubmitted ? true : false}
                    />
                    <label
                      htmlFor="eMail"
                      className="form-label col-form-label-sm"
                    >
                      e-Mail: <span className="required">*</span>
                    </label>
                  </div>
                </div>
                <div className={phone ? "col-md-3" : "col-md-6"}>
                  <div className="form-floating mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      name="Telefono"
                      id="Telefono"
                      placeholder="Telefono"
                      onChange={handlePhone}
                      value={form.Telefono}
                      disabled={isSubmitted ? true : false}
                    />
                    <label
                      htmlFor="Telefono"
                      className="form-label col-form-label-sm"
                    >
                      Teléfono:
                    </label>
                  </div>
                </div>
                <div
                  className="col-md-3"
                  style={phone ? { display: "block" } : { display: "none" }}
                >
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="Horario"
                      id="Horario"
                      placeholder="Horario"
                      onChange={addElement}
                      value={form.Horario}
                      disabled={isSubmitted ? true : false}
                    />
                    <label
                      htmlFor="Horario"
                      className="form-label col-form-label-sm"
                    >
                      Horario contacto:
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-6"
                  style={centro ? { display: "block" } : { display: "none" }}
                >
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="NumeroAlumnos"
                      id="NumeroAlumnos"
                      placeholder="NumeroAlumnos"
                      onChange={addElement}
                      value={form.NumeroAlumnos}
                      disabled={isSubmitted ? true : false}
                    />
                    <label
                      htmlFor="NumeroAlumnos"
                      className="form-label col-form-label-sm"
                    >
                      Número de alumnos (aprox.):
                    </label>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  style={centro ? { display: "block" } : { display: "none" }}
                >
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="Actividad"
                      name="Actividad"
                      required={centro ? true : false}
                      style={{ fontSize: "14px" }}
                      onChange={addElement}
                      value={form.Actividad}
                      disabled={isSubmitted ? true : false}
                    >
                      <option value="" disabled defaultValue>
                        Selecciona una actividad
                      </option>
                      {actividades.map((data, index) => (
                        <option
                          value={data}
                          key={index}
                          disabled={data.substr(0, 3) === "---" ? true : null}
                        >
                          {data}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor="Actividad"
                      className="form-label col-form-label-sm"
                    >
                      Actividad a consultar: <span className="required">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  rows="3"
                  className="form-control"
                  required
                  name="Consulta"
                  id="Consulta"
                  placeholder="Consulta"
                  style={{ height: "100%" }}
                  onChange={addElement}
                  value={form.Consulta}
                  disabled={isSubmitted ? true : false}
                ></textarea>
                <label
                  htmlFor="Consulta"
                  className="form-label col-form-label-sm"
                >
                  Escribe tu consulta: <span className="required">*</span>
                </label>
              </div>

              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  required
                  id="Acepta"
                  name="Acepta"
                  checked={conditions}
                  onClick={handleClickConditions}
                />

                <label htmlFor="Acepta">
                  {parse(
                    "<a href='/avisolegal' style='text-decoration:underline' target='_blank'>He leído</a> y acepto la Política de Privacidad y Protección de Datos personales"
                  )}
                </label>
              </div>
              <div className="mb-3 button-submit">
                <button
                  className={isSubmitted ? "btn btn-light" : "btn btn-primary"}
                  type="submit"
                  disabled={isSubmitted ? true : false}
                >
                  {isSubmitted ? "Enviando... Espere..." : "Enviar Consulta"}
                </button>
              </div>
            </form>

            <p className="content__contact__frame__inner__conditions">
              En cumplimiento de lo establecido en el{" "}
              <a
                href="https://www.boe.es/doue/2016/119/L00001-00088.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del
                Consejo de 27 de abril de 2016 relativo a la protección de las
                personas físicas)
              </a>
              y en{" "}
              <a
                href="http://noticias.juridicas.com/base_datos/Admin/lo15-1999.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                la Ley Orgánica 15/1999, el 13 de diciembre, de Protección de
                Datos de Carácter Personal
              </a>{" "}
              , se informa al usuario que mediante la cumplimentación del
              formulario de contacto se otorga el consentimiento expreso de que
              sus datos personales queden incorporados y sean tratados en un
              fichero, propiedad de teatroluminaria.com, con la finalidad de
              poder gestionar la solicitud. Este fichero será gestionado por el
              responsable de fichero que se indica en nuestro{" "}
              <a
                href="http://teatroluminaria.com/aviso-legal.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                aviso legal
              </a>
              .<br />
              Asimismo, salvo que el usuario manifieste expresamente lo
              contrario, se podrán utilizar sus datos personales para mantenerle
              informado de noticias y novedades relacionadas con nuestros
              servicios. teatroluminaria.com se compromete a, que bajo ningún
              concepto, estos datos serán compartidos o cedidos a terceros e
              informamos de la posibilidad de ejercer los derechos de acceso,
              rectificación, cancelación y oposición a la recogida de los datos
              de carácter personal mediante correo electrónico o bien
              directamente mediante los enlaces de baja automática contenidos en
              todas las comunicaciones enviadas por teatroluminaria.com, o bien,
              mediante este mismo formulario de contacto.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contact;
