import { Link } from "react-router-dom";
import parse from "html-react-parser";
import data from "./showsdata.json";
import proximamente from "../../assets/img/cards/Proximamente.png";
import SEO from "@americanexpress/react-seo";

function Cards(props) {
  let newdata = "";
  let seoTitle = "";
  let seoDescription = "";
  let seoKeywords = [];
  let seoUrl = "";
  let seoImg = "";
  if (props.dirpath === "ingles") {
    newdata = data.filter((data) => data.lang === "ingles");
    seoTitle = "Teatro en inglés";
    seoDescription =
      "Teatro en inglés para colegios, centros cívicos, bibliotecas, salas de teatro, etc. Obras de teatro en inglés de calidad para tu centro educativo.";
    seoKeywords = [
      "teatro en inglés",
      "espectáculos en inglés",
      "teatro para colegios",
      "teatro escolar",
      "teatro bilingüe",
      "teatro de títeres",
      "luz negra",
      "gran formato",
      "primaria",
      "educación primaria",
      "obras de teatro en inglés",
      "teatro educativo",
    ];
    seoUrl = "https://www.teatroluminaria.com/teatroingles";
    seoImg = "https://www.teatroluminaria.com/img/cards/card_trapped.png";
  } else if (props.dirpath === "castellano") {
    newdata = data.filter((data) => data.lang === "castellano");
    seoTitle = "Teatro en castellano";
    seoDescription =
      "Teatro en castellano para salas de teatro, centros cívicos, bibliotecas, colegios, etc. Obras de teatro de calidad para toda España con títeres y luz negra.";
    seoKeywords = [
      "teatro infantil",
      "espectáculos infantiles",
      "teatro",
      "teatro escolar",
      "teatro castellano",
      "teatro de títeres",
      "luz negra",
      "gran formato",
      "salas de teatro",
      "teatro familiar",
      "obras de teatro",
      "teatro educativo",
      "teatro de luz negra",
    ];
    seoUrl = "https://www.teatroluminaria.com/teatro";
    seoImg = "https://www.teatroluminaria.com/img/cards/card_sinfonias2.png";
  }
  const shows = newdata[0].shows;
  return shows.map((data, index) => {
    return (
      <div
        className="content__shows__show"
        data-aos="flip-up"
        data-aos-once="true"
        key={index}
      >
        <SEO
          title={seoTitle}
          description={seoDescription}
          keywords={seoKeywords}
          siteUrl={seoUrl}
          image={{
            src: seoImg,
          }}
        />
        <img
          src={proximamente}
          className="content__shows__show__head__soon"
          style={
            data.soon === "true"
              ? { visibility: "visible" }
              : { visibility: "hidden" }
          }
          alt="soon"
        />
        <div className="content__shows__show__head">
          <Link to={data.link}>
            <div
              className="content__shows__show__head__img"
              style={{
                backgroundImage: "url(" + data.image + ".webp),url(" + data.image + ".png)",
              }}
            ></div>
          </Link>
        </div>
        <Link to={data.link} className="content__shows__show__button">
          <button>{parse(data.title)}</button>
        </Link>
      </div>
    );
  });
}

export default Cards;
