// import env from "react-dotenv";
import { useState, useRef } from "react";
import ReactPasswordToggleIcon from "react-password-toggle-icon";

function PrivateDownload(props) {
  const [title, setTitle] = useState("");
  const [state, setState] = useState(false);
  let inputRef = useRef();
  const showIcon = () => <i className="fa fa-eye" aria-hidden="true"></i>;
  const hideIcon = () => <i className="fa fa-eye-slash" aria-hidden="true"></i>;

  function CheckPass() {
    if (title === process.env.REACT_APP_ACT_PASS) {
      window.location.href = props.download;
      setState(false);
      setTitle("");
    } else {
      setState(true);
      setTitle("");
    }
  }
  return (
    <div
      className="content__show__downloads__frame__inner__activitydownload"
      style={props.css ? { display: "block" } : { display: "none" }}
    >
      <h2 className="content__show__downloads__frame__inner__activitydownload__text">
        Introduce la contraseña para descargar el pack:
      </h2>
      <div className="content__show__downloads__frame__inner__activitydownload__form">
        <div
          style={{
            position: "relative",
            display: "block",
          }}
        >
          <input
            className="content__show__downloads__frame__inner__activitydownload__form__input"
            type="password"
            placeholder="Introducir Contraseña"
            value={title}
            autoComplete="off"
            ref={inputRef}
            style={{
              display: "block",
              width: "100%",
              border: "0px",
              borderBottom: "1px solid orange",
              padding: "5px",
              outline: "none",
            }}
            onChange={(event) => {
              setTitle(event.target.value);
              setState(false);
            }}
          />
          <ReactPasswordToggleIcon
            inputRef={inputRef}
            showIcon={showIcon}
            hideIcon={hideIcon}
          />
        </div>
        <button
          className="content__show__downloads__frame__inner__activitydownload__form__button"
          onClick={CheckPass}
        >
          Descargar
        </button>
        <p
          className="content__show__downloads__frame__inner__activitydownload__form__subtext"
          style={state ? { visibility: "visible" } : { visibility: "hidden" }}
        >
          Contraseña incorrecta
        </p>
      </div>
    </div>
  );
}

export default PrivateDownload;
