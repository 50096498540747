import { useState } from "react";
import Carousel from "../commons/Carousel/Carousel";
import Dossier from "./Dossier";
import PrivateDownload from "./PrivateDownload";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import data from "./showdata.json";

import CarasTeatro from "../../assets/img/icons/CarasTeatro.webp";
import Edades from "../../assets/img/icons/Edades.webp";
import Reloj from "../../assets/img/icons/Reloj.webp";
import Actor from "../../assets/img/icons/Actor.webp";
import Idioma from "../../assets/img/icons/Idioma.webp";
import Escalera from "../../assets/img/icons/Escalera.webp";
import Pluma from "../../assets/img/icons/Pluma.webp";
import Papel from "../../assets/img/icons/PapelyLapiz.webp";
import Euro from "../../assets/img/icons/Euro.webp";
import DownDossier from "../../assets/img/buttons/pdf_download.webp";
import DownActivity from "../../assets/img/buttons/activity_download.webp";
import SEO from "@americanexpress/react-seo";
// import ReactGA from "react-ga";

function Show(props) {
  // ReactGA.pageview("/" + props.dirpath);
  const [activityClick, setactivityClick] = useState(false);
  function handleClick() {
    setactivityClick(!activityClick);
  }
  let newdata = "";
  if (props.dirpath === "acall") {
    newdata = data.filter((data) => data.show === "acall");
  } else if (props.dirpath === "atrapada") {
    newdata = data.filter((data) => data.show === "atrapada");
  } else if (props.dirpath === "beeyourself") {
    newdata = data.filter((data) => data.show === "beeyourself");
  } else if (props.dirpath === "blacklight") {
    newdata = data.filter((data) => data.show === "blacklight1");
  } else if (props.dirpath === "blacklight2") {
    newdata = data.filter((data) => data.show === "blacklight2");
  } else if (props.dirpath === "bouchontraveller") {
    newdata = data.filter((data) => data.show === "bouchontraveller");
  } else if (props.dirpath === "emotion") {
    newdata = data.filter((data) => data.show === "emotion");
  } else if (props.dirpath === "quest") {
    newdata = data.filter((data) => data.show === "quest");
  } else if (props.dirpath === "storytelling") {
    newdata = data.filter((data) => data.show === "storytelling");
  } else if (props.dirpath === "trapped") {
    newdata = data.filter((data) => data.show === "trapped");
  } else if (props.dirpath === "violet") {
    newdata = data.filter((data) => data.show === "violet");
  } else if (props.dirpath === "sinfonias") {
    newdata = data.filter((data) => data.show === "sinfonias");
  } else if (props.dirpath === "sinfonias2") {
    newdata = data.filter((data) => data.show === "sinfonias2");
  } else if (props.dirpath === "bouchonviajero") {
    newdata = data.filter((data) => data.show === "bouchonviajero");
  }
  const show = newdata[0].data[0];
  const seoURL = "https://www.teatroluminaria.com/" + props.dirpath;
  // const seoImg =
  //   "https://www.teatroluminaria.com" + show.imgcover + "-small.png";

  return (
    <main className="content">
      <SEO
        title={show.titleshow}
        description={show.seodescription}
        keywords={show.seokeywords}
        siteUrl={seoURL}
        locale="es-ES"
        robots={["index", "follow"]}
      />

      <div className="content__show">
        {/*  Caratula  */}
        <div
          className="content__show__cover"
          data-aos="zoom-in"
          data-aos-duration="500"
          data-aos-once="true"
        >
          <div className="content__show__cover__frame">
            <img
              className="content__show__cover__frame__img"
              src={show.imgcover + ".webp"}
              alt={show.altcover}
            />
          </div>
        </div>

        {/*  Sinopsis  */}
        <div
          className="content__show__synopsis"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          <div className="content__show__synopsis__frame">
            <div className="content__show__synopsis__frame__inner">
              <div className="content__show__synopsis__frame__inner__text">
                {parse(show.synopsis)}
              </div>
              {/*  Informacion y precios  */}
              <Link
                to="/contacto"
                className="content__show__synopsis__frame__inner__button"
              >
                <button>Información y precios</button>
              </Link>
            </div>
          </div>
        </div>

        {/*  Caracteristicas (inglés)  */}
        <div
          className="content__show__features"
          data-aos="zoom-in"
          data-aos-duration="500"
          style={
            show.language === "english"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <h2 className="content__show__features__title">Características:</h2>
          <div className="content__show__features__frame">
            <div className="content__show__features__frame__inner">
              <div className="content__show__features__frame__inner__feature">
                <img src={CarasTeatro} alt="tipo" />
                <p>{parse(show.type)}</p>
              </div>
              <div className="content__show__features__frame__inner__feature">
                <img src={Edades} alt="edades" />
                <p>{parse(show.ages)}</p>
              </div>
              <div className="content__show__features__frame__inner__feature">
                <img src={Reloj} alt="tiempo" />
                <p>{parse(show.duration)}</p>
              </div>

              <div className="content__show__features__frame__inner__feature">
                <img src={Actor} alt="actores" />
                <p>{parse(show.actors)}</p>
              </div>
              <div className="content__show__features__frame__inner__feature">
                <img src={Idioma} alt="idiomas" />
                <p>{parse(show.languages)}</p>
              </div>
              <div className="content__show__features__frame__inner__feature">
                <img src={Escalera} alt="niveles" />
                <p>{parse(show.levels)}</p>
              </div>

              <div className="content__show__features__frame__inner__feature">
                <img src={Pluma} alt="extras" />
                <p>{parse(show.extras)}</p>
              </div>
              <div className="content__show__features__frame__inner__feature">
                <img src={Papel} alt="actividades" />
                <p>{parse(show.activities)}</p>
              </div>
              <div className="content__show__features__frame__inner__feature">
                <img src={Euro} alt="precio" />
                <p>{parse(show.price)}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Fichas (castellano) */}
        <div className="content__show__sheets">
          {/* Ficha de técnica aplicada (castellano) */}
          <div
            className="content__show__sheets__sheet--1"
            data-aos="flip-up"
            data-aos-duration="500"
            style={
              show.language === "spanish"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="content__show__sheets__sheet__frame">
              <div className="content__show__sheets__sheet__frame__inner">
                <div className="content__show__sheets__sheet__frame__inner__text technique">
                  <h3>
                    <u>Técnica</u>
                  </h3>
                  <p>{parse(show.technique)}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Ficha técnica (castellano) */}
          <div
            className="content__show__sheets__sheet--2"
            data-aos="slide-right"
            data-aos-duration="500"
            style={
              show.language === "spanish"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="content__show__sheets__sheet__frame">
              <div className="content__show__sheets__sheet__frame__inner">
                <div className="content__show__sheets__sheet__frame__inner__text datasheet">
                  <h3>
                    <u>Ficha Técnica</u>
                  </h3>
                  <p>{parse(show.datasheet)}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Ficha artística (castellano) */}
          <div
            className="content__show__sheets__sheet--3"
            data-aos="slide-left"
            data-aos-duration="500"
            style={
              show.language === "spanish"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="content__show__sheets__sheet__frame">
              <div className="content__show__sheets__sheet__frame__inner">
                <div className="content__show__sheets__sheet__frame__inner__text artsheet">
                  <h3>
                    <u>Ficha Artística</u>
                  </h3>
                  <p>{parse(show.artsheet)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Galería */}
        <div
          className="content__show__gallery"
          data-aos="slide-left"
          data-aos-duration="500"
        >
          <h2 className="content__show__gallery__title">Galería:</h2>
          <div className="content__show__gallery__frame">
            <div className="content__show__gallery__frame__inner">
              <Carousel
                id={show.carouselid}
                ident={show.carouselid}
                type="show"
                interval="5000"
                aos={true}
                buttons={true}
              />
            </div>
          </div>
        </div>

        {/* Dossier online */}
        <div
          className="content__show__dossier"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          <h2 className="content__show__dossier__title">Dossier Online:</h2>
          <div className="content__show__dossier__frame">
            <div className="content__show__dossier__frame__inner">
              <Dossier images={show.dossieronline} />

              <p className="content__show__dossier__download">
                <a
                  href={show.dossierpdf}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Ver a pantalla completa
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Descargas  */}
        <div
          className="content__show__downloads"
          data-aos="slide-right"
          data-aos-duration="500"
        >
          <h2 className="content__show__downloads__title">Descargas:</h2>
          <div className="content__show__downloads__frame">
            <div className="content__show__downloads__frame__inner">
              <a href={show.dossierzip} className="button">
                <img src={DownDossier} alt="download_dossier" />
              </a>
              <button
                onClick={handleClick}
                className="button"
                style={
                  show.activityzip === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <img src={DownActivity} alt="download_activity" />
              </button>
              <PrivateDownload
                css={activityClick}
                download={show.activityzip}
              />
            </div>
          </div>
        </div>

        {/* Descarga Privada */}

        {/* Video */}
        <div
          // data-aos="slide-up"
          // data-aos-duration="500"
          className="content__show__video"
          style={
            show.videolink === "" ? { display: "none" } : { display: "block" }
          }
        >
          <h2 className="content__show__video__title">Vídeo:</h2>

          <iframe
            className="content__show__video__video"
            title={show.videotitle}
            src={show.videolink}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        {/* Creative Commons */}
        <div className="content__show__license">
          {/* <a
            rel="license noreferrer"
            href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.es_ES"
            target="_blank"
          >
            <img
              alt="Licencia de Creative Commons"
              style={{ borderWidth: 0 }}
              src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
            />
          </a>
          <p>
            <i>'{parse(show.titleshow)}'</i> y todo su contenido pertenece a
            &nbsp;
            <a
              rel="noreferrer"
              href="https://www.teatroluminaria.com"
              target="_blank"
            >
              Teatro Luminaria
            </a>
            , registrado con una licencia:
            <br />
            <a
              rel="license noreferrer"
              href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.es_ES"
              target="_blank"
            >
              Creative Commons Attribution-NonCommercial-NoDerivatives 4.0
              Internacional License
            </a>
            .
          </p> */}
        </div>
      </div>
    </main>
  );
}
export default Show;
