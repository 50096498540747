function CarouselElement(props) {
  return (
    <div className={props.active ? "carousel-item active" : "carousel-item"}>
      <a
        href={props.link}
        style={props.link ? { cursor: "pointer" } : { cursor: "default" }}
      >

          <img
            src={props.image + ".webp"}
            className="d-block w-100"
            alt={props.altern}
            width="100%"
            height="100%"
          />
      </a>
    </div>
  );
}

export default CarouselElement;
