import React from "react";

const DossierPage = React.forwardRef((props, ref) => {
  return props.images.map((data, index) => {
    return (
      <div className="page" ref={ref}>
        <img style={{ width: "100%" }} alt={index} src={data} />
      </div>
    );
  });
});

export default DossierPage;
