import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/commons/Header/Header";
import Home from "./components/Home";
import Shows from "./components/ShowsMenu/Shows.jsx";
import Show from "./components/Shows/Show.jsx";
import Activity from "./components/Activities/CuentaTalleres.jsx";
import Blog from "./components/Blog/Blog.jsx";
import Company from "./components/Company/Company.jsx";
import Contact from "./components/Contact/Contact.jsx";
import Legal from "./components/Legal/Legal.jsx";
import Page404 from "./components/commons/page404.jsx";
import Footer from "./components/commons/Footer/Footer";
import Pack from "./components/Pack/Pack";
// import Covid from "./components/Covid/Covid";
import Regalo from "./components/Regalo/Regalo";
import ScrollToTop from "./components/commons/ScrollToTop";
import { UserProvider } from "./components/commons/UserContext";
// import ReactGA from "react-ga"; //Google Analytics
// ReactGA.pageview(window.location.pathname + window.location.search);

// Para que funcione las redirecciones al 404 de cualquier ruta por larga que sea y niveles que tenga es importante que este configurado el .htaccess y que en el head del index este incluida la etiqueta <base href="/" />

function App() {
  useEffect(() => {
    // ReactGA.initialize("UA-44091115-1");
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="backcontainer">
      <UserProvider>
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route exact path="/" element={<Home dirpath="home" />} />
            <Route path="/teatroingles" element={<Shows dirpath="ingles" />} />
            <Route path="/teatro" element={<Shows dirpath="castellano" />} />
            <Route path="/acall" element={<Show dirpath="acall" />} />
            <Route path="/atrapada" element={<Show dirpath="atrapada" />} />
            <Route
              path="/avisolegal"
              element={<Legal dirpath="avisolegal" />}
            />
            <Route
              path="/beeyourself"
              element={<Show dirpath="beeyourself" />}
            />
            <Route path="/blacklight" element={<Show dirpath="blacklight" />} />
            <Route
              path="/blacklight2"
              element={<Show dirpath="blacklight2" />}
            />
            <Route path="/blog" element={<Blog dirpath="blog" />} />
            <Route
              path="/teatro-en-ingles"
              element={<Blog dirpath="teatro-en-ingles" />}
            />
            <Route
              path="/bouchontraveller"
              element={<Show dirpath="bouchontraveller" />}
            />
            <Route
              path="/bouchonviajero"
              element={<Show dirpath="bouchonviajero" />}
            />
            <Route path="/compania" element={<Company dirpath="compania" />} />
            <Route path="/contacto" element={<Contact dirpath="contacto" />} />
            <Route path="/cookies" element={<Legal dirpath="cookies" />} />
            <Route
              path="/cuentacuentos"
              element={<Activity dirpath="cuentacuentos" />}
            />
            <Route path="/emotion" element={<Show dirpath="emotion" />} />
            <Route path="/packahorro" element={<Pack dirpath="packahorro" />} />
            <Route path="/quest" element={<Show dirpath="quest" />} />
            <Route path="/sinfonias" element={<Show dirpath="sinfonias" />} />
            <Route path="/sinfonias2" element={<Show dirpath="sinfonias2" />} />
            <Route
              path="/storytelling"
              element={<Show dirpath="storytelling" />}
            />
            <Route path="/talleres" element={<Activity dirpath="talleres" />} />
            <Route path="/trapped" element={<Show dirpath="trapped" />} />
            <Route path="/violet" element={<Show dirpath="violet" />} />
            {/* <Route path="/infoCovid" element={<Covid dirpath="covid"/>} /> */}
            <Route
              path="/regalo/sinfonias"
              element={<Regalo dirpath="regalo_sinfonias" />}
            />
            <Route
              path="/regalo/sinfonias2"
              element={<Regalo dirpath="regalo_sinfonias2" />}
            />
            {/* BLOG */}
            <Route path="/blog" element={<Blog dirpath="blog" />} />
            <Route
              path="/blog/bienvenidos-blog"
              element={<Blog dirpath="bienvenidos-blog" />}
            />
            <Route
              path="/blog/innovacion-teatro-aprendizaje-ingles"
              element={<Blog dirpath="innovacion-teatro-aprendizaje-ingles" />}
            />
            <Route
              path="/blog/juegos-cortos-clase-ingles"
              element={<Blog dirpath="juegos-cortos-clase-ingles" />}
            />
            <Route
              path="/blog/lesson-plan-creatividad-ingles-chefcraft"
              element={
                <Blog dirpath="lesson-plan-creatividad-ingles-chefcraft" />
              }
            />
            <Route
              path="/blog/desarrollo-lenguaje-utero-1"
              element={<Blog dirpath="desarrollo-lenguaje-utero-1" />}
            />
            <Route
              path="/blog/desarrollo-lenguaje-nacimiento-2"
              element={<Blog dirpath="desarrollo-lenguaje-nacimiento-2" />}
            />
            <Route
              path="/blog/la-importancia-del-teatro-en-ingles-para-colegios-parte-1"
              element={
                <Blog dirpath="la-importancia-del-teatro-en-ingles-para-colegios-parte-1" />
              }
            />
            <Route
              path="/blog/la-importancia-del-teatro-en-ingles-para-colegios-parte-2"
              element={
                <Blog dirpath="la-importancia-del-teatro-en-ingles-para-colegios-parte-2" />
              }
            />

            {/* 404 */}
            <Route path="*" element={<Page404 dirpath="page404" />} />
          </Routes>
          <Footer />
        </Router>
      </UserProvider>
    </div>
  );
}

export default App;
